import React from "react";

const Footer = () => {
  return (
    <footer className="bg-[#1B2B3F] text-white py-12">
      <div className="max-w-7xl mx-auto px-4">
        {/* Logo Section */}
        <div className="flex justify-center mb-6">
          <div className="flex items-center gap-2 filter brightness-0 invert">
            <div>
              <img
                className="w-21 h-20"
                src="/LandLayout.com_PNG.png"
                alt="LandLayout Logo"
              />
            </div>
          </div>
        </div>

        {/* Disclaimer Texts */}
        <div className="mb-8">
          <p className="text-gray-300 text-sm leading-relaxed mx-auto">
            Land Layout has endeavoured to ascertain the requirement of RERA
            registration. However, the advertiser claims that there is no
            requirement for such registration. Users are cautioned accordingly.
            Land Layout acts merely as a medium for posting information and
            content. It is clarified that the data available on the website is
            not physically verified and hence no representation or warranty is
            expressly or impliedly given to its accuracy in any form. The User
            is advised to do thorough research and due diligence before making
            any decision regarding investment. Nothing contained on the
            platform shall deem to constitute legal advice, solicitation,
            invitation etc. in any form whatsoever.
          </p>
        </div>

        {/* Bottom Bar */}
        <div className="border-t border-gray-700 pt-4 flex flex-col md:flex-row justify-between items-center">
          {/* Copyright */}
          <div className="text-sm text-gray-400 mb-4 md:mb-0">
            © 2024 LandLayout.com All rights reserved
          </div>

          {/* Social Links */}
          <div className="flex items-center gap-4 mb-4 md:mb-0">
            <a
              href="#"
              className="text-gray-400 hover:text-white transition-colors text-sm"
            >
              Instagram
            </a>
            <span className="text-gray-600 text-sm">•</span>
            <a
              href="#"
              className="text-gray-400 hover:text-white transition-colors text-sm"
            >
              Twitter
            </a>
            <span className="text-gray-600 text-sm">•</span>
            <a
              href="#"
              className="text-gray-400 hover:text-white transition-colors text-sm"
            >
              Facebook
            </a>
          </div>

          {/* Legal Links */}
          <div className="flex items-center gap-4">
            <a
              href="#"
              className="text-gray-400 hover:text-white transition-colors text-sm"
            >
              Terms & Condition
            </a>
            <a
              href="#"
              className="text-gray-400 hover:text-white transition-colors text-sm"
            >
              Privacy Policy
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
