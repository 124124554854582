import React, { useState } from 'react';
import { FaUser, FaHome, FaHeart, FaPlus, FaSearch, FaSignInAlt, FaChevronDown } from 'react-icons/fa';

const Header = ({ 
  isLoggedIn, 
  onLogin, 
  onPostProperty, 
  variant = 'default',
  showBottomNav = true 
}) => {
  const [showSignInModal, setShowSignInModal] = useState(false);
  const [showContactModal, setShowContactModal] = useState(false);
  const [landsDropdownOpen, setLandsDropdownOpen] = useState(false);
  const [language, setLanguage] = useState('En');

  const isWhiteBackground = variant === 'solid';

  return (
    <header className="fixed w-full top-0 z-50 transition-all duration-300">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center h-16">
          {/* Logo */}
          <div className="flex-shrink-0">
            <img 
              className={`h-8 w-auto ${!isWhiteBackground ? 'filter brightness-0 invert' : ''}`}
              src="/LandLayout.com_PNG.png" 
              alt="LandLayout Logo" 
            />
          </div>

          {/* Centered Navigation */}
          <nav className="hidden md:flex items-center space-x-8 justify-center flex-grow font-montserrat">
            <div className="relative group">
              <button
                className="flex items-center space-x-1 text-white group-hover:text-gray-200"
                onClick={() => setLandsDropdownOpen(!landsDropdownOpen)}
              >
                <span>LANDS</span>
                <FaChevronDown className="h-4 w-4 ml-1" />
              </button>
              {landsDropdownOpen && (
                <div className="absolute mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
                  {/* Dropdown content */}
                </div>
              )}
            </div>
            
            <a href="/career" className="text-white hover:text-gray-200">
              CAREER
            </a>
            
            <button 
              onClick={() => setShowSignInModal(true)}
              className="text-white hover:text-gray-200"
            >
              LOGIN
            </button>
          </nav>

          {/* Right Section */}
          <div className="flex items-center space-x-4">
            {/* Language Toggle */}
            <button 
      onClick={() => setLanguage(language === 'En' ? 'ગુ' : 'En')}
      className="w-14 h-7 rounded-full bg-gray-200 flex items-center px-1 relative cursor-pointer"
    >
      <div 
        className={`absolute w-6 h-6 bg-white rounded-full transition-transform duration-200 ${
          language === 'En' ? 'translate-x-0' : 'translate-x-7'
        }`} 
      />
      <span 
        className={`text-xs text-white absolute left-2 ${
          language === 'En' ? 'opacity-100' : 'opacity-0'
        }`}
      >
        En
      </span>
      <span 
        className={`text-xs text-white absolute right-2 ${
          language === 'En' ? 'opacity-0' : 'opacity-100'
        }`}
      >
        ગુ
      </span>
    </button>

            {/* Selling Land Button */}
            <button
  onClick={onPostProperty}
  className="text-black px-4 py-2 rounded flex items-center space-x-2"
  style={{ backgroundColor: '#FFCC08', transition: 'background-color 0.2s ease' }}
  onMouseOver={(e) => (e.currentTarget.style.backgroundColor = '#FFD43B')}
  onMouseOut={(e) => (e.currentTarget.style.backgroundColor = '#FFCC08')}
>
  <span className="font-medium font-montserrat">
    Selling Land?{' '}
    <span className="text-white font-montserrat">ADD FREE</span>
  </span>
</button>

          </div>
        </div>
      </div>

      {/* Bottom Navigation for Mobile */}
      {showBottomNav && (
        <div className="fixed bottom-0 left-0 right-0 bg-white border-t md:hidden">
          <div className="grid grid-cols-5 gap-1 py-2">
            <a href="#home" className="flex flex-col items-center text-gray-600">
              <FaHome className="h-6 w-6" />
              <span className="text-xs mt-1">Home</span>
            </a>
            <a href="#wishlist" className="flex flex-col items-center text-gray-600">
              <FaHeart className="h-6 w-6" />
              <span className="text-xs mt-1">Wishlists</span>
            </a>
            <button 
              className="flex flex-col items-center justify-center relative"
              onClick={() => setShowContactModal(true)}
            >
              <div className="bg-yellow-500 rounded-full p-3 -mt-6">
                <FaPlus className="h-6 w-6 text-white" />
              </div>
            </button>
            <a href="#explore" className="flex flex-col items-center text-gray-600">
              <FaSearch className="h-6 w-6" />
              <span className="text-xs mt-1">Explore</span>
            </a>
            <button 
              onClick={() => setShowSignInModal(true)}
              className="flex flex-col items-center text-gray-600"
            >
              <FaSignInAlt className="h-6 w-6" />
              <span className="text-xs mt-1">Login</span>
            </button>
          </div>
        </div>
      )}
    </header>
  );
};

export default Header;