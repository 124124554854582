import React, { useState } from 'react';
import { Share2,MapPin, Heart,Share,Download} from 'lucide-react';
const LandList = () => {
  const [activeTab, setActiveTab] = useState('plots');
  const [selectedCity, setSelectedCity] = useState('Bengaluru');
  const [selectedOwner, setSelectedOwner] = useState('Owner');

  const tabs = [
    { id: 'plots', label: 'Plots / Sites', color: '#1B8739' },
    { id: 'layouts', label: 'Layouts', color: '#fff' },
    { id: 'farmlands', label: 'Framlands', color: '#fff' },
    { id: 'commercial', label: 'Commercial Lands', color: '#fff' }
  ];

  return (
    <div className="w-full max-w-7xl mx-auto p-4 mt-4">
      {/* Title */}
      <h1 className="text-3xl text-gray-800 mb-6 text-center">Lorem ipsum</h1>
      
      {/* Navigation Container */}
      <div className="flex items-center justify-between w-full">
        {/* Left side - Tabs */}
        <div className="flex space-x-2">
          {tabs.map((tab) => (
            <button
              key={tab.id}
              onClick={() => setActiveTab(tab.id)}
              className={`px-4 py-2 rounded-md text-sm transition-colors duration-200 
                ${activeTab === tab.id 
                  ? 'bg-[#1B8739] text-white' 
                  : 'bg-white text-gray-700 border border-gray-200 hover:bg-gray-50'}`}
            >
              {tab.label}
            </button>
          ))}
        </div>

        {/* Right side - Filters */}
        <div className="flex items-center space-x-4">
          {/* City Dropdown */}
          <div className="relative">
            <select
              value={selectedCity}
              onChange={(e) => setSelectedCity(e.target.value)}
              className="appearance-none bg-transparent border-none text-[#1B8739] py-2 pr-8 pl-2 cursor-pointer focus:outline-none"
            >
              <option value="Bengaluru">Bengaluru</option>
              <option value="Mumbai">Mumbai</option>
              <option value="Delhi">Delhi</option>
            </select>
            <div className="absolute right-2 top-1/2 transform -translate-y-1/2">
              <svg 
                className="w-4 h-4 text-[#1B8739]" 
                fill="none" 
                stroke="currentColor" 
                viewBox="0 0 24 24"
              >
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
              </svg>
            </div>
          </div>

          {/* Owner Dropdown */}
          <div className="relative">
            <select
              value={selectedOwner}
              onChange={(e) => setSelectedOwner(e.target.value)}
              className="appearance-none bg-transparent border-none text-[#1B8739] py-2 pr-8 pl-2 cursor-pointer focus:outline-none"
            >
              <option value="Owner">Owner</option>
              <option value="Agent">Agent</option>
              <option value="Builder">Builder</option>
            </select>
            <div className="absolute right-2 top-1/2 transform -translate-y-1/2">
              <svg 
                className="w-4 h-4 text-[#1B8739]" 
                fill="none" 
                stroke="currentColor" 
                viewBox="0 0 24 24"
              >
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
              </svg>
            </div>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mt-6">
      {/* Card 1 */}
      <div className="rounded-lg overflow-hidden shadow-sm">
        <div className="aspect-[4/3] relative">
          <img 
            src="https://plus.unsplash.com/premium_photo-1669227514138-d5e43441b5e7?q=80&w=1932&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" 
            alt="Green fields with hills"
            className="w-full h-full object-cover"
          />
        </div>
        <div className="p-4">
          <div className="flex items-center justify-between mb-3">
            <div className="flex items-center gap-2">
              <span className="text-lg font-semibold text-blue-600">Lorem ipsum</span>
              <span className="px-2 py-1 bg-green-600 text-white text-sm rounded">Owner</span>
            </div>
            <div className="flex gap-2">
              <Heart className="w-6 h-6 text-gray-600" />
              <Share2 className="w-6 h-6 text-gray-600" />
            </div>
          </div>
          <div className="flex items-center gap-1 text-gray-600 mb-3">
            <MapPin className="w-4 h-4" />
            <span>Location</span>
          </div>
          <p className="text-gray-600 text-sm">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
            dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat.
          </p>
        </div>
      </div>

      {/* Card 2 */}
      <div className="rounded-lg overflow-hidden shadow-sm"> {/* Added top margin to lower the card */}
        <div className="aspect-[4/3] relative">
          <img 
            src="https://images.unsplash.com/photo-1461175827210-5ceac3e39dd2?q=80&w=1933&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" 
            alt="Terraced rice fields with mountains"
            className="w-full h-full object-cover"
          />
        </div>
        <div className="p-4">
          <div className="flex items-center justify-between mb-3">
            <div className="flex items-center gap-2">
              <span className="text-lg font-semibold text-blue-600">Lorem ipsum</span>
              <span className="px-2 py-1 bg-green-600 text-white text-sm rounded">Owner</span>
            </div>
            <div className="flex gap-2">
              <Heart className="w-6 h-6 text-gray-600" />
              <Share2 className="w-6 h-6 text-gray-600" />
            </div>
          </div>
          <div className="flex items-center gap-1 text-gray-600 mb-3">
            <MapPin className="w-4 h-4" />
            <span>Location</span>
          </div>
          <p className="text-gray-600 text-sm">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
            dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat.
          </p>
        </div>
      </div>

      {/* Card 3 */}
      <div className="rounded-lg overflow-hidden shadow-sm">
        <div className="aspect-[4/3] relative">
          <img 
            src="https://images.unsplash.com/photo-1625378314354-0edfa5f28259?q=80&w=2043&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" 
            alt="Tractor in field at sunset"
            className="w-full h-full object-cover"
          />
        </div>
        <div className="p-4">
          <div className="flex items-center justify-between mb-3">
            <div className="flex items-center gap-2">
              <span className="text-lg font-semibold text-blue-600">Lorem ipsum</span>
              <span className="px-2 py-1 bg-green-600 text-white text-sm rounded">Owner</span>
            </div>
            <div className="flex gap-2">
              <Heart className="w-6 h-6 text-gray-600" />
              <Share2 className="w-6 h-6 text-gray-600" />
            </div>
          </div>
          <div className="flex items-center gap-1 text-gray-600 mb-3">
            <MapPin className="w-4 h-4" />
            <span>Location</span>
          </div>
          <p className="text-gray-600 text-sm">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
            dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat.
          </p>
        </div>
      </div>

      {/* Card 3 */}
      <div className="rounded-lg overflow-hidden shadow-sm">
        <div className="aspect-[4/3] relative">
          <img 
            src="https://images.unsplash.com/photo-1625378314354-0edfa5f28259?q=80&w=2043&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" 
            alt="Tractor in field at sunset"
            className="w-full h-full object-cover"
          />
        </div>
        <div className="p-4">
          <div className="flex items-center justify-between mb-3">
            <div className="flex items-center gap-2">
              <span className="text-lg font-semibold text-blue-600">Lorem ipsum</span>
              <span className="px-2 py-1 bg-green-600 text-white text-sm rounded">Owner</span>
            </div>
            <div className="flex gap-2">
              <Heart className="w-6 h-6 text-gray-600" />
              <Share2 className="w-6 h-6 text-gray-600" />
            </div>
          </div>
          <div className="flex items-center gap-1 text-gray-600 mb-3">
            <MapPin className="w-4 h-4" />
            <span>Location</span>
          </div>
          <p className="text-gray-600 text-sm">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
            dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat.
          </p>
        </div>
      </div>

      {/* Card 3 */}
      <div className="rounded-lg overflow-hidden shadow-sm">
        <div className="aspect-[4/3] relative">
          <img 
            src="https://images.unsplash.com/photo-1625378314354-0edfa5f28259?q=80&w=2043&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" 
            alt="Tractor in field at sunset"
            className="w-full h-full object-cover"
          />
        </div>
        <div className="p-4">
          <div className="flex items-center justify-between mb-3">
            <div className="flex items-center gap-2">
              <span className="text-lg font-semibold text-blue-600">Lorem ipsum</span>
              <span className="px-2 py-1 bg-green-600 text-white text-sm rounded">Owner</span>
            </div>
            <div className="flex gap-2">
              <Heart className="w-6 h-6 text-gray-600" />
              <Share2 className="w-6 h-6 text-gray-600" />
            </div>
          </div>
          <div className="flex items-center gap-1 text-gray-600 mb-3">
            <MapPin className="w-4 h-4" />
            <span>Location</span>
          </div>
          <p className="text-gray-600 text-sm">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
            dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat.
          </p>
        </div>
      </div>
      {/* Card 3 */}
      <div className="rounded-lg overflow-hidden shadow-sm">
        <div className="aspect-[4/3] relative">
          <img 
            src="https://images.unsplash.com/photo-1625378314354-0edfa5f28259?q=80&w=2043&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" 
            alt="Tractor in field at sunset"
            className="w-full h-full object-cover"
          />
        </div>
        <div className="p-4">
          <div className="flex items-center justify-between mb-3">
            <div className="flex items-center gap-2">
              <span className="text-lg font-semibold text-blue-600">Lorem ipsum</span>
              <span className="px-2 py-1 bg-green-600 text-white text-sm rounded">Owner</span>
            </div>
            <div className="flex gap-2">
              <Heart className="w-6 h-6 text-gray-600" />
              <Share2 className="w-6 h-6 text-gray-600" />
            </div>
          </div>
          <div className="flex items-center gap-1 text-gray-600 mb-3">
            <MapPin className="w-4 h-4" />
            <span>Location</span>
          </div>
          <p className="text-gray-600 text-sm">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
            dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat.
          </p>
        </div>
      </div>
     

    </div>
    <button
  className="mx-auto block px-8 py-2 text-gray-600 border border-gray-300 rounded-md hover:bg-gray-50 transition-colors duration-200 text-sm mt-16"
  onClick={() => {/* Add your click handler here */}}
>
  View more
</button>

{/* Green Bar Above Header */}
<div className="flex justify-center mb-4 mt-16">
          <div className="w-16 h-1 bg-green-600"></div>
        </div>

        {/* Header */}
        <h2 className="text-3xl font-semibold text-center mb-8">Featured Layouts</h2>

        <div className="relative w-full max-w-4xl mx-auto rounded-2xl overflow-hidden shadow-lg bg-white">
      {/* Main Image */}
      <img 
        src="https://plus.unsplash.com/premium_photo-1669227514138-d5e43441b5e7?q=80&w=1932&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
        alt="Green landscape with river"
        className="w-full h-[400px] object-cover"
      />
      
      {/* Overlay Card */}
      <div className="absolute bottom-6 right-6 bg-white rounded-xl p-6 max-w-[500px] shadow-lg">
        {/* Header with Title and Price */}
        <div className="flex justify-between items-start mb-4">
          <div>
            <h2 className="text-[#0066CC] text-2xl font-medium mb-2">Lorem ipsum</h2>
            <div className="flex items-center text-gray-500">
              <svg className="w-5 h-5 mr-2" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
              </svg>
              <span>Location</span>
            </div>
          </div>
          <div className="text-[#0066CC] text-2xl font-medium">$ 00000</div>
        </div>
        
        {/* Description */}
        <p className="text-gray-600 mb-6 text-sm leading-relaxed">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
          labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
          laboris nisi ut aliquip ex ea commodo consequat.
        </p>
        
        {/* Action Buttons */}
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-4">
            <button className="text-gray-600 hover:text-gray-800 transition-colors">
              <Heart className="w-6 h-6" />
            </button>
            <button className="flex items-center gap-2 text-gray-600 hover:text-gray-800 px-4 py-2 border border-gray-300 rounded-md transition-colors">
              <Share className="w-5 h-5" />
              <span>Share</span>
            </button>
            <button className="flex items-center gap-2 text-gray-600 hover:text-gray-800 px-4 py-2 border border-gray-300 rounded-md transition-colors">
              <Download className="w-5 h-5" />
              <span>Download</span>
            </button>
          </div>
          
          <button className="text-[#00A651] hover:text-[#008543] font-medium transition-colors">
            See details
          </button>
        </div>
      </div>
    </div>

    <div className="relative w-full max-w-4xl mx-auto rounded-2xl overflow-hidden shadow-lg bg-white mt-4">
      {/* Main Image */}
      <img 
        src="https://plus.unsplash.com/premium_photo-1669227514138-d5e43441b5e7?q=80&w=1932&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
        alt="Green landscape with river"
        className="w-full h-[400px] object-cover"
      />
      
      {/* Overlay Card */}
      <div className="absolute bottom-6 right-6 bg-white rounded-xl p-6 max-w-[500px] shadow-lg">
        {/* Header with Title and Price */}
        <div className="flex justify-between items-start mb-4">
          <div>
            <h2 className="text-[#0066CC] text-2xl font-medium mb-2">Lorem ipsum</h2>
            <div className="flex items-center text-gray-500">
              <svg className="w-5 h-5 mr-2" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
              </svg>
              <span>Location</span>
            </div>
          </div>
          <div className="text-[#0066CC] text-2xl font-medium">$ 00000</div>
        </div>
        
        {/* Description */}
        <p className="text-gray-600 mb-6 text-sm leading-relaxed">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
          labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
          laboris nisi ut aliquip ex ea commodo consequat.
        </p>
        
        {/* Action Buttons */}
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-4">
            <button className="text-gray-600 hover:text-gray-800 transition-colors">
              <Heart className="w-6 h-6" />
            </button>
            <button className="flex items-center gap-2 text-gray-600 hover:text-gray-800 px-4 py-2 border border-gray-300 rounded-md transition-colors">
              <Share className="w-5 h-5" />
              <span>Share</span>
            </button>
            <button className="flex items-center gap-2 text-gray-600 hover:text-gray-800 px-4 py-2 border border-gray-300 rounded-md transition-colors">
              <Download className="w-5 h-5" />
              <span>Download</span>
            </button>
          </div>
          
          <button className="text-[#00A651] hover:text-[#008543] font-medium transition-colors">
            See details
          </button>
        </div>
      </div>
    </div>

    <button
  className="mx-auto block px-8 py-2 text-gray-600 border border-gray-300 rounded-md hover:bg-gray-50 transition-colors duration-200 text-sm mt-16"
  onClick={() => {/* Add your click handler here */}}
>
  View more
</button>

    </div>

    
  );
};

export default LandList;